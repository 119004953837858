<template>
  <section class="">
    <h2
      class="w-28 h-11 py-2 px-4 mt-24 mx-auto bg-indigo-500 rounded-full text-white flex items-center justify-center font-bold"
    >
      Feedbacks
    </h2>

    <div class="relative">
      <div class="swiper" ref="feedbackRate">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide"
            v-for="(user, i) in feedbacksData"
            :key="'feedbackRate-' + i"
          >
            <div class="mx-auto mt-8 flex justify-center gap-x-2">
              <span>⭐</span>
              <span>⭐</span>
              <span>⭐</span>
              <span>⭐</span>
              <span>⭐</span>
            </div>

            <div>
              <p
                class="my-8 mx-auto px-4 text-2xl lg:text-4xl text-center font-bold lg:w-1/2"
              >
                “{{ $t(feedbacksData[activeIndex].userComment) }}”
              </p>
            </div>
          </div>
        </div>
      </div>

      <div
        class="mx-4 lg:mx-0 my-6 h-1 bg-stone-900 flex justify-center rounded-3xl"
      >
        <div class="w-1/3 bg-indigo-500 rounded-3xl"></div>
      </div>
      <div
        class="swiper absolute -bottom-10 lg:-bottom-16 right-0 left-0"
        ref="feedbackSwiper"
      >
        <div class="swiper-wrapper">
          <div
            class="swiper-slide text-xl lg:text-4xl flex flex-col justify-end"
            v-for="(user, i) in feedbacksData"
            :key="'feedbackSwiper-' + i"
          >
            <p
              class="mx-auto pointer-events-none z-10"
              :class="
                activeIndex === i ? 'text-white font-bold' : 'text-gray-500 '
              "
            >
              {{ user.userName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { Swiper } from "swiper";
import "swiper/css";

const activeIndex = ref(0);
const feedbackRateInstance = ref<Swiper | null>(null);
const feedbackSwiperInstance = ref<Swiper | null>(null);

const feedbackRate = ref(null);
const feedbackSwiper = ref(null);

const feedbacksData = [
  {
    userName: "Vinicius",
    userComment: "landingPage.feedbacks.feedback1",
  },
  {
    userName: "Vani",
    userComment: "landingPage.feedbacks.feedback2",
  },
  {
    userName: "Giovanna",
    userComment: "landingPage.feedbacks.feedback3",
  },
  {
    userName: "Zack",
    userComment: "landingPage.feedbacks.feedback4",
  },
  {
    userName: "Amanda",
    userComment: "landingPage.feedbacks.feedback5",
  },
  {
    userName: "Edward",
    userComment: "landingPage.feedbacks.feedback6",
  },
];

onMounted(() => {
  feedbackSwiperInstance.value = new Swiper(
    <HTMLElement>(<unknown>feedbackSwiper.value),
    {
      initialSlide: 1,
      grabCursor: true,
      slidesPerView: 2.1,
      centeredSlides: true,
      on: {
        slideChange: (swiper) => {
          if (activeIndex.value === swiper.realIndex) {
            return;
          }

          activeIndex.value = swiper.realIndex;
          nextTick(() => {
            feedbackRateInstance.value?.slideTo(swiper.realIndex);
          });
        },
      },
    }
  );

  feedbackRateInstance.value = new Swiper(
    <HTMLElement>(<unknown>feedbackRate.value),
    {
      initialSlide: 0,
      grabCursor: true,
      slidesPerView: 1,
      spaceBetween: 100,
      on: {
        slideChange: (swiper) => {
          if (activeIndex.value === swiper.realIndex) {
            return;
          }

          activeIndex.value = swiper.realIndex;
          nextTick(() => {
            feedbackSwiperInstance.value?.slideTo(swiper.realIndex);
          });
        },
      },
    }
  );
});

onBeforeUnmount(() => {
  feedbackSwiperInstance.value?.destroy();
  feedbackRateInstance.value?.destroy();
});
</script>
