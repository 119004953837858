<template>
  <main class="font-satoshi overflow-x-hidden">
    <LandingPageNav />
    <LandingPageHeader />
    <LandingPageSlides />
    <LandingPageGraph />
    <LandingPageProducts />
    <LandingPageFeedback />
    <LandingPageServices />
    <LandingPageFaq />
    <LandingPageMarketing />
    <LandingPageFooter />
  </main>
</template>

<script lang="ts" setup>
definePageMeta({
  layout: "none",
});

useHead({
  title: "Reality Studio",
  meta: [
    { hid: "robots", name: "robots", content: "index, follow" },
    {
      name: "description",
      content: "Start creating amazing avatars with Reality Studio!",
    },
    {
      hid: "og:title",
      property: "og:title",
      content: "Reality Studio",
    },
    {
      hid: "og:description",
      property: "og:description",
      content: "Start creating amazing avatars with Reality Studio!",
    },
  ],
});
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Satoshi-Variable";
  src: url("/web/fonts/Satoshi-Variable.woff2") format("woff2"),
    url("/web/fonts/Satoshi-Variable.woff") format("woff"),
    url("/web/fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

.font-satoshi {
  font-family: "Satoshi-Variable", sans-serif;
}

.center-image {
  box-shadow: 0px 0px 100px #5364fe6f;
}

::-webkit-scrollbar {
  width: 10px !important;
  height: 5px;
  background-color: transparent;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #4b5563;
  border-radius: 10px;
}
</style>
