<template>
  <section id="aiTools" class="mt-6 md:mt-16 page-container relative">
    <h2
      class="w-fit h-11 py-2 px-4 mx-auto bg-indigo-500 rounded-3xl text-white flex items-center justify-center font-bold lg:hidden"
    >
      {{ $t("landingPage.menuNav.aiTools") }}
    </h2>
    <h3
      class="mt-6 font-bold text-3xl md:text-5xl text-center"
      v-motion-slide-visible-once-left
    >
      ✨ {{ $t("landingPage.aiTools.title") }}.
    </h3>

    <!-- Desktop -->
    <div class="hidden lg:flex mt-14">
      <div class="swiper hero-swiper">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide group"
            v-for="(slide, i) in slideContents"
            :key="i"
          >
            <div class="h-[700px] w-[700px] relative mx-auto">
              <video
                :src="slide"
                class="rounded-3xl object-cover h-full w-full pointer-events-none"
                :class="{
                  'opacity-20': i !== activeSwiperIndex,
                  'opacity-100': i === activeSwiperIndex,
                }"
                :ref="`slide-${i}`"
                :muted="true"
                preload="auto"
                autoplay
                playsinline
              />
              <DefaultButton
                class="absolute top-4 right-4 opacity-0 pointer-events-none transition-all duration-300 group-hover:pointer-events-auto group-hover:opacity-100"
                size="fit"
                color="transparent"
                @click="toggleMute"
              >
                <span v-if="isMuted" class="material-icons text-4xl"
                  >volume_off</span
                >
                <span v-if="!isMuted" class="material-icons text-4xl"
                  >volume_up</span
                >
              </DefaultButton>
            </div>
          </div>
        </div>
      </div>
    </div>

    <LandingPageBlueCircle
      width="400px"
      height="300px"
      blur="200px"
      class="hidden lg:block ms-[50%] top-[50%]"
    />

    <!-- Mobile -->
    <div class="flex lg:hidden justify-center mt-8">
      <div class="grid grid-cols-2 gap-x-4">
        <video
          v-for="(src, index) in slideContents"
          :key="index"
          :ref="`video-${index}`"
          @click="playThisVideo(index)"
          @ended="playNextVideo"
          class="mb-5 h-72 w-44 rounded-3xl cursor-pointer transition-opacity duration-500 lazy bg-white"
          :class="{
            'opacity-20': index !== activeSwiperIndex,
            'opacity-100': index === activeSwiperIndex,
            'mt-11': index === 0,
            'bg-[#DDDDDD]': index === 1,
          }"
          playsinline
          preload="auto"
          :muted="true"
          :src="src"
        />
      </div>
    </div>
    <p
      class="mt-8 mx-auto max-w-sm lg:max-w-xl w-full text-xl lg:text-4xl text-center text-gray-500"
      v-motion-slide-visible-once-right
    >
      {{ $t("landingPage.aiTools.description") }}.
    </p>
  </section>
</template>

<script lang="ts" setup>
import Swiper, { Swiper as SwiperClass } from "swiper";

import "swiper/css";

const slideContents = ref<string[]>([
  "https://cdn.realitystudio.ai/assets/web/landing-page/videos/inspireme2.mp4",
  "https://cdn.realitystudio.ai/assets/web/landing-page/videos/reimagine.mp4",
  "https://cdn.realitystudio.ai/assets/web/landing-page/videos/createimage.mp4",
]);

const swiperInstance = ref<SwiperClass | null>(null);
const activeSwiperIndex = ref<number>(0);
const isMuted = ref<boolean>(true);

const isMobile = computed(() => false); // @todo: fix this

const playThisVideo = (index: number) => {
  const videoElement = document.querySelector<HTMLVideoElement>(
    `[ref="video-${index}"]`
  );
  if (videoElement) {
    videoElement.play();
  }
};

const playNextVideo = () => {
  const videoElement = document.querySelector<HTMLVideoElement>(
    `[ref="video-${activeSwiperIndex.value}"]`
  );
  if (videoElement) {
    videoElement.pause();
    videoElement.currentTime = 0;
    activeSwiperIndex.value =
      (activeSwiperIndex.value + 1) % slideContents.value.length;
    const nextVideoElement = document.querySelector<HTMLVideoElement>(
      `[ref="video-${activeSwiperIndex.value}"]`
    );
    if (nextVideoElement) {
      nextVideoElement.play();
    }
  }
};

const toggleMute = () => {
  isMuted.value = !isMuted.value;
  const videoElement = document.querySelector<HTMLVideoElement>(
    `[ref="slide-${activeSwiperIndex.value}"]`
  );
  if (videoElement) {
    videoElement.muted = isMuted.value;
  }
};

const onSlideChange = (swiper: SwiperClass) => {
  if (activeSwiperIndex.value !== swiper.realIndex) {
    activeSwiperIndex.value = swiper.realIndex;
  }

  const activeVideoEl = document.querySelector<HTMLVideoElement>(
    `[ref="slide-${activeSwiperIndex.value}"]`
  );
  for (let i = 0; i < slideContents.value.length; i++) {
    const videoEl = document.querySelector<HTMLVideoElement>(
      `[ref="slide-${i}"]`
    );
    if (videoEl && videoEl !== activeVideoEl) {
      videoEl.pause();
      videoEl.muted = true;
    }
  }

  if (activeVideoEl) {
    activeVideoEl.muted = isMuted.value;
    activeVideoEl.play();
  }
};

onMounted(() => {
  if (!isMobile.value) {
    swiperInstance.value = new Swiper(".hero-swiper", {
      grabCursor: true,
      slidesPerView: 2,
      speed: 400,
      spaceBetween: 100,
      centeredSlides: true,
      initialSlide: 1,
      resistanceRatio: 0.1,
      on: {
        slideChange: onSlideChange,
      },
    });
  } else {
    const videoElement =
      document.querySelector<HTMLVideoElement>(`[ref="video-0"]`);
    if (videoElement) {
      videoElement.play();
    }
  }
});

onBeforeUnmount(() => {
  if (swiperInstance.value) {
    swiperInstance.value.destroy();
  }
});
</script>

<style scoped>
.lazy::after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 8799%;
}
</style>
