<template>
  <section
    id="solutions"
    class="w-full relative"
    :class="{ 'page-container': !isMobile }"
  >
    <h2
      class="w-28 h-11 py-2 px-4 mt-28 mx-auto bg-indigo-500 rounded-full text-white flex items-center justify-center font-semibold"
      v-motion-slide-visible-once-left
    >
      {{ $t("landingPage.menuNav.solutions") }}
    </h2>
    <div class="page-container">
      <h3
        class="font-bold mt-6 lg:mt-10 text-3xl md:text-5xl text-center"
        v-motion-slide-visible-once-right
      >
        {{ $t("landingPage.solutions.title") }}.
      </h3>
      <p
        class="mt-8 mx-auto max-w-sm lg:max-w-5xl w-full text-xl lg:text-4xl text-center text-gray-500"
        v-motion-slide-visible-once-left
      >
        {{ $t("landingPage.solutions.description") }}.
      </p>
    </div>
    <div class="flex justify-center">
      <nav
        class="flex md:justify-center px-4 mt-8 font-bold text-md page-container overflow-x-auto no-scrollbar"
      >
        <DefaultButton
          class="w-fit py-4 px-8 rounded-full"
          :color="currentCategory === 'lora' ? 'primary' : 'outline-gray'"
          @click="() => setCategory('lora')"
        >
          LoRas
        </DefaultButton>
        <DefaultButton
          class="w-fit py-4 px-8 mx-6 rounded-full"
          :color="currentCategory === 'filters' ? 'primary' : 'outline-gray'"
          @click="() => setCategory('filters')"
        >
          {{ $t("landingPage.solutions.products2") }}
        </DefaultButton>
        <DefaultButton
          class="w-fit py-4 px-8 rounded-full"
          :color="currentCategory === 'tools' ? 'primary' : 'outline-gray'"
          @click="() => setCategory('tools')"
        >
          {{ $t("landingPage.solutions.products3") }}
        </DefaultButton>
      </nav>
    </div>
    <div class="mt-8 ml-4 swiper products-swiper">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide last:pr-4"
          v-for="(item, i) in items"
          :key="i"
          :class="{ 'mt-32': i % 2 === 0 }"
        >
          <img class="rounded-t-2xl" :src="item.image" :alt="item.title" />
          <div
            class="bg-zinc-900 h-32 rounded-b-xl flex items-center justify-center"
          >
            <p class="font-bold text-xl px-5">{{ $t(item.title) }}</p>
          </div>
        </div>
      </div>
    </div>
    <LandingPageBlueCircle
      width="264px"
      height="264px"
      blur="320px"
      class="right-60 -z-10 lg:hidden"
    />
  </section>
</template>

<script setup lang="ts">
import Swiper from "swiper";

const itemsData = {
  lora: [
    {
      title: "landingPage.solutions.loras.item1",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/loras/product-1.jpg",
    },
    {
      title: "landingPage.solutions.loras.item2",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/loras/product-2.jpg",
    },
    {
      title: "landingPage.solutions.loras.item3",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/loras/product-3.jpg",
    },
    {
      title: "landingPage.solutions.loras.item4",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/loras/product-4.jpg",
    },
    {
      title: "landingPage.solutions.loras.item5",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/loras/product-5.jpg",
    },
  ],
  filters: [
    {
      title: "landingPage.solutions.filters.item1",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/filters/product-1.jpg",
    },
    {
      title: "landingPage.solutions.filters.item2",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/filters/product-2.jpg",
    },
    {
      title: "landingPage.solutions.filters.item3",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/filters/product-3.jpg",
    },
    {
      title: "landingPage.solutions.filters.item4",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/filters/product-4.jpg",
    },
    {
      title: "landingPage.solutions.filters.item5",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/filters/product-5.jpg",
    },
  ],
  tools: [
    {
      title: "landingPage.solutions.tools.item1",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/tools/product-1.jpg",
    },
    {
      title: "landingPage.solutions.tools.item2",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/tools/product-2.jpg",
    },
    {
      title: "landingPage.solutions.tools.item3",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/tools/product-3.jpg",
    },
    {
      title: "landingPage.solutions.tools.item4",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/tools/product-4.jpg",
    },
    {
      title: "landingPage.solutions.tools.item5",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/tools/product-5.jpg",
    },
    {
      title: "landingPage.solutions.tools.item6",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/tools/product-6.jpg",
    },
    {
      title: "landingPage.solutions.tools.item7",
      image: "https://cdn.realitystudio.ai/assets/web/landing-page/products/tools/product-7.jpg",
    },
  ],
};

const swiperInstance = ref<Swiper | null>(null);
const currentCategory = ref("lora");
const edgeReached = ref(false);

const isMobile = computed(() => {
  return false;
});

const items = computed(() => {
  return itemsData[currentCategory.value];
});

const setCategory = (category: string) => {
  currentCategory.value = category;
};

onMounted(() => {
  swiperInstance.value = new Swiper(".products-swiper", {
    slidesPerView: isMobile.value ? 1.15 : 4.15,
    spaceBetween: 16,
    grabCursor: true,
    resistanceRatio: 0.5,
  });
});

onBeforeUnmount(() => {
  swiperInstance.value?.destroy();
});
</script>
