<template>
	<header class="text-6xl md:text-7xl text-center md:mt-12 page-container pt-28 md:pt-20" id="home">
		<h1 class="w-80 md:w-full mx-auto">{{ $t('landingPage.header.title') }}</h1>

		<div class="mt-6 lg:mt-10 relative">
			<div v-motion-roll-visible-once-bottom>
				<div class="bg-indigo-500 mx-auto w-full lg:w-7/12 h-20 md:h-36 rotate-2"></div>
			</div>
			<p
				class="font-extrabold text-5xl md:text-7xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
			>
				<span ref="word"></span>
				<span class="input-cursor" v-if="currentLetterIndex > 0"></span>
			</p>

			<LandingPageBlueCircle
				width="150px"
				height="150px"
				blur="100px"
				class="lg:hidden left-1/2 top-2/3 -z-10"
			></LandingPageBlueCircle>
		</div>
		<div class="page-container">
			<DefaultButton href="/login" class="mx-auto lg:w-96 h-20 mt-10 rounded-3xl font-bold">
				{{ $t('landingPage.getStarted') }}
				<span class="material-icons ms-1 font-bold">open_in_new</span>
			</DefaultButton>
		</div>
	</header>
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from 'vue'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()

const word = ref<HTMLSpanElement | null>(null)
const currentWordIndex = ref<number>(0)
const typing = ref<boolean>(true)
const currentLetterIndex = ref<number>(0)
const speedInMs = 100
const wordList = computed(() => [
	t('landingPage.header.productive'),
	t('landingPage.header.creative'),
	t('landingPage.header.professional'),
	t('landingPage.header.valuable'),
	t('landingPage.header.skilled'),
	t('landingPage.header.precise'),
])

const startTypingEffect = () => {
	if (!typing.value) return

	if (currentLetterIndex.value < wordList.value[currentWordIndex.value].length) {
		if (word.value) {
			word.value.innerText += wordList.value[currentWordIndex.value].charAt(currentLetterIndex.value)
		}
		currentLetterIndex.value++
		setTimeout(startTypingEffect, speedInMs)
	} else {
		typing.value = false
		setTimeout(clearWord, 500)
	}
}

const clearWord = () => {
	if (typing.value) return

	if (currentLetterIndex.value > 0) {
		if (word.value) {
			word.value.innerText = word.value.innerText.slice(0, -1)
		}
		currentLetterIndex.value--
		setTimeout(clearWord, speedInMs)
	} else {
		typing.value = true
		currentWordIndex.value = (currentWordIndex.value + 1) % wordList.value.length
		currentLetterIndex.value = 0
		setTimeout(startTypingEffect, speedInMs)
	}
}

onMounted(() => {
	startTypingEffect()
})
</script>

<style scoped>
.input-cursor {
	position: absolute;
	display: inline-block;
	width: 6px;
	right: -6px;
	height: 60px;
	bottom: 0;
	background-color: white;
	animation: blink 0.6s linear infinite alternate;
}

@keyframes blink {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	60% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}
</style>
