<template>
  <section class="page-container" id="pricing">
    <h2
      class="w-24 h-11 py-2 px-4 mt-20 lg:mt-56 mx-auto bg-indigo-500 rounded-full text-white flex items-center justify-center font-bold"
    >
      {{ $t("landingPage.menuNav.pricing") }}
    </h2>
    <h3 class="font-bold mt-6 lg:mt-10 text-3xl md:text-5xl text-center">
      {{ $t("landingPage.services.title") }}
    </h3>
    <p
      class="mt-4 lg:mt-10 mx-auto max-w-sm lg:max-w-5xl w-full text-xl lg:text-4xl text-center text-gray-500"
    >
      {{ $t("landingPage.services.description") }}
    </p>

    <div
      class="mt-12 flex justify-center items-center gap-x-6 text-lg font-medium page-container"
    >
      <p class="w-16">{{ $t("landingPage.services.monthlyPlan") }}</p>

      <DefaultButton size="fit" color="none" @click="onToggleMonthlyPlan">
        <input
          type="checkbox"
          id="check"
          class="hidden"
          v-model="isDisplayingYearlyPlan"
        />
        <label
          for="check"
          class="relative bg-zinc-900 w-[70px] h-[32px] h-8 rounded-full flex items-center py-[6px] px-[4px] transition-all duration-500 ease-in-out border -z-10"
        >
          <span
            class="absolute bg-white rounded-full w-5 h-5 transition-all duration-500 ease-in-out"
          ></span>
        </label>
      </DefaultButton>

      <div class="-ms-3">
        <p>{{ $t("landingPage.services.annualPlan") }}</p>
        <div
          class="mb-1 bg-white py-1 px-2 rounded-full w-fit h-6 text-stone-800 text-xs font-bold text-center mt-1"
        >
          up to 30% OFF
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-y-6 gap-x-8 lg:grid-cols-4 mt-14 md:mx-10">
      <div
        class="p-6 rounded-xl flex flex-col justify-between h-full"
        v-motion-slide-visible-once-left
        v-for="plan in plans"
        :key="plan.title"
        :class="{
          'bg-[#5363FE]': plan.highlight,
          'bg-gray-900 ': !plan.highlight,
        }"
      >
        <div>
          <p class="text-2xl font-bold">{{ $t(plan.title) }}</p>

          <div class="flex items-center mt-6">
            <p class="text-4xl me-2 font-bold">
              {{ plan.prices[isDisplayingYearlyPlan ? "yearly" : "monthly"] }}
            </p>
            <p
              class="font-medium"
              :class="{
                ' text-white': plan.highlight,
                'text-zinc-500 ': !plan.highlight,
              }"
            >
              {{ $t("landingPage.services.perMonth") }}
            </p>
          </div>

          <div class="flex flex-col gap-y-4 my-6">
            <p
              class="flex items-center gap-x-1"
              v-for="feature in plan.features"
              :key="feature"
            >
              <span class="material-icons">check_circle</span> {{ $t(feature) }}
            </p>
          </div>
        </div>
        <DefaultButton
          href="/my-account"
          :color="plan.free ? 'outline' : 'primary'"
        >
          {{
            plan.free
              ? $t("landingPage.services.forFree")
              : $t("landingPage.services.plan")
          }}
        </DefaultButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
const isDisplayingYearlyPlan = ref(true);

const plans = [
  {
    title: "landingPage.services.plans.free.title",
    prices: {
      monthly: "$0.00",
      yearly: "$0.00",
    },
    features: [
      "landingPage.services.plans.free.feature1",
      "landingPage.services.plans.free.feature2",
      "landingPage.services.plans.free.feature3",
    ],
    free: true,
  },
  {
    title: "landingPage.services.plans.basic.title",
    prices: {
      monthly: "$3.50",
      yearly: "$3.20",
    },
    features: [
      "landingPage.services.plans.basic.feature1",
      "landingPage.services.plans.basic.feature2",
      "landingPage.services.plans.basic.feature3",
      "landingPage.services.plans.basic.feature4",
    ],
  },
  {
    title: "landingPage.services.plans.professional.title",
    prices: {
      monthly: "$6.90",
      yearly: "$5.50",
    },
    features: [
      "landingPage.services.plans.professional.feature1",
      "landingPage.services.plans.professional.feature2",
      "landingPage.services.plans.professional.feature3",
      "landingPage.services.plans.professional.feature4",
    ],
    highlight: true,
  },
  {
    title: "landingPage.services.plans.enterprise.title",
    prices: {
      monthly: "$24.90",
      yearly: "$17.90",
    },
    features: [
      "landingPage.services.plans.enterprise.feature1",
      "landingPage.services.plans.enterprise.feature2",
      "landingPage.services.plans.enterprise.feature3",
      "landingPage.services.plans.enterprise.feature4",
    ],
  },
];

const onToggleMonthlyPlan = () => {
  isDisplayingYearlyPlan.value = !isDisplayingYearlyPlan.value;
};
</script>

<style>
#check:checked ~ label {
  background-color: #5363fe;
}

#check:checked ~ label span {
  transform: translateX(40px);
}
</style>
